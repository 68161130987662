@page{
    size: A4;
    margin: 0
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }
}

.print-view-wrapper{
    padding-top: 42mm;
    padding-left: 20mm;
}

.print-text {
    font-family: Arial, sans-serif;

}