.next-button {
    background-color: #4caf50!important;
    color: white!important;
}

.next-button-disabled {
    background-color: lightgrey!important;
    color: black!important;
}

.next-button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}