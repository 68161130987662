.side-by-side-images-wrapper {
    display: flex;
    justify-content: space-between;
}

.side-by-side-images {
    width: 49%;
    height: auto;
}

.gruner-select-value {
    width: 40rem;
    height: calc(40rem * 1.392030156);
    position: relative;
    background-size: cover;
}

.gruner-select-value-text {
    color: red;
    font-weight: bold;
    font-size: 4rem;
    position: absolute;
    top: 10rem;
    left: 24rem;
}