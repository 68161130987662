.product-selection-list-item {
    background-color: #64b5f6!important;
    font-weight: bold!important;
}

.product-selection-list-item:hover {
    background-color: #bbdefb!important;
}

.product-selection-list-item-selected {
    background-color: #4caf50!important;
    font-weight: bold!important;
}

.product-selection-list-item-selected:hover {
    background-color: #a5d6a7!important;
}

.product-image {
    width: 50%;
    display: block;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}