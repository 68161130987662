.start-page-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.start-page-button {
    width: 25rem;
    height: 25rem;
    margin: 1rem!important;
    padding: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}