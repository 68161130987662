.calc-value {
    width: 40rem;
    height: 40rem;
    position: relative;
    background-size: cover;
}

.calc-value-text {
    color: red;
    font-weight: bold;
    font-size: 4rem;
    position: absolute;
    top: 10.2rem;
    left: 24rem;
}