.giftcard-image-wrapper {
    display: flex;
    justify-content: center;
}

.giftcard-image {
    width: 500px;
    height: 1168px;
    background-size: cover;
    border: 2px solid darkgrey;
    position: relative;
}

.giftcard-image-front {
    width: 500px;
    height: 1087px;
    background-size: cover;
    border: 2px solid darkgrey;
    position: relative;
}

.fill-text {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}

.red-text {
    color:red;
    font-weight: bold;
    text-align: center;
}