.text-field-wrapper{
    display: flex;
    justify-content: center;
}

.text-field-width {
    width: 15rem;
}

.text-field {
    font-size: 2rem!important;
    font-weight: bold!important;
}

.balance-paper {
    padding: 1rem;
    background-color: #a5d6a7!important;
}